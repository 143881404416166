<template>
  <Layout style="min-height: 100vh">
    <AppHeader />
    <Content class="container">
      <h2 class="title">修改密码</h2>
      <Form class="form">
        <FormItem prop="password">
          <Input type="password" v-model="currentPassword" placeholder="密码">
            <Icon type="ios-lock-outline" slot="prepend"></Icon>
          </Input>
        </FormItem>
        <FormItem prop="password">
          <Input type="password" v-model="password" placeholder="新密码">
            <Icon type="ios-lock-outline" slot="prepend"></Icon>
          </Input>
        </FormItem>
        <FormItem prop="passwordConfirmation">
          <Input
            type="password"
            v-model="passwordConfirmation"
            placeholder="重复新密码"
            autocomplete="off"
          >
            <Icon type="ios-lock-outline" slot="prepend"></Icon>
          </Input>
        </FormItem>
        <FormItem>
          <div class="button-container">
            <Button
              type="primary"
              size="large"
              @click="changePassword"
              :loading="isLoading"
            >
              确认
            </Button>
          </div>
        </FormItem>
      </Form>
    </Content>
  </Layout>
</template>

<script>
import {
  Layout,
  Content,
  FormItem,
  Form,
  Input,
  Icon,
  Button,
  Message,
} from "view-design";
import AppHeader from "../components/AppHeader";
import { changePassword } from "../service/auth";

export default {
  name: "ChangePassword",
  components: {
    Layout,
    Content,
    AppHeader,
    Form,
    FormItem,
    Input,
    Icon,
    Button,
  },
  data() {
    return {
      passwordConfirmation: "",
      password: "",
      currentPassword: "",
      isLoading: false,
    };
  },
  methods: {
    async changePassword() {
      if (this.password !== this.passwordConfirmation) {
        return Message.error("新确认密码不相同");
      }
      this.isLoading = true;
      try {
        const data = {
          oldPassword: this.currentPassword,
          password: this.password,
        };
        await changePassword(data);
        Message.success("密码已修改");
        this.password = "";
        this.passwordConfirmation = "";
        this.currentPassword = "";
      } catch (error) {
        console.error(error);
        Message.error(error.response.data.message);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
  width: 100%;
  padding: 0 20px 40px;
  margin: 0 auto;
}

.title {
  margin-top: 50px;
  margin-bottom: 25px;
  text-align: center;
}

.button-container {
  text-align: center;
}
</style>
